define(["handlebars"], function(Handlebars) { return Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<object width=\"660\" height=\"370\">\n<param class=\"movie-url\" name=\"movie\" value=\""
    + escapeExpression((typeof depth0 === functionType ? depth0.apply(depth0) : depth0))
    + "\" />\n<param name=\"allowFullScreen\" value=\"true\" />\n<param name=\"allowscriptaccess\" value=\"always\" /><embed class=\"main-video\" type=\"application/x-shockwave-flash\" width=\"660\" height=\"370\" src=\""
    + escapeExpression((typeof depth0 === functionType ? depth0.apply(depth0) : depth0))
    + "\" allowfullscreen=\"true\" allowscriptaccess=\"always\"></embed>\n</object>";
  return buffer;
  }); });